import {
  API_USER_PATIENT_PROFILE,
  CheckSecretExpirationOutput,
  GetPatientDetailsForPdfEndOfTreatment,
  GetPatientDetailsForPdfEndOfTreatmentDetails,
  GetPatientDetailsForPdfEndOfTreatmentOutput,
  GetPatientDetailsForPdfHmoLetter,
  GetPatientDetailsForPdfLetterForMedInsurance,
  GetPatientDetailsForPdfSummaryOfConsultation,
  GetPatientRequiredDetailsForGmel29Output,
  GetPatientsForSearchInput,
  IPatientCardInfo,
  ISearchPatient,
  MakePatientBackDoorInput,
  MakePatientBackDoorResult,
  MakePatientOnHoldInput,
  MakePatientUnHoldInput,
  PatientDetailsForDocumentSign,
  PatientDetailsForPdfEmptyLetter,
  PatientDetailsForPdfGimel29,
  PatientDetailsForPreviewSurvey,
  PatientVerifyPhoneNumberInput,
  ruleIDNumber,
  UserPatientProfile,
  UserPatientProfileCreateInput,
  UserPatientProfileEditGeneralInput,
  UserPatientProfilePatchInput,
  ValidatePatientIdNumberInput,
  ValidatePatientsFieldsUniqueInput,
} from './models';
import { getFileInfo, isFileLike } from 'utils/file-uploader';
import { ServiceMigrations } from 'services/migrations';
import { DynamicService } from 'utils/service';
import { apiRtk, isMutationFulfilled, RTK_TAGS } from 'utils/rtk-query';
import { debounceAsync, getRandomString } from 'utils/other';
import { addMinutes, endOfYear, isAfter, startOfYear } from 'date-fns';
import { PatchPartial, Unset } from 'utils/types';
import * as yup from 'yup';
import { ServiceUsePatientProfileSmsMessages } from '../user-patient-profile-sms-messages';
import { ServiceUserPatientProfileTelecoms } from '../user-patient-profile-telecoms';
import { ServicePatientPrescription } from '../user-patient-profile-prescriptions';
import { ServicePatientDocuments } from '../user-patient-profile-documents';
import { ServicePatientFormDocuments } from '../user-patient-profile-form-documents';
import { apiTodoTasks, schemaTodoTaskCreate, ServiceTodoTasks } from '../todo-tasks';
import { ServiceUserPatientProfileNotes } from '../user-patient-profile-notes';
import { ServiceClinicalMeetings } from '../clinical-meetings';
import { ServiceSupportMeetings } from '../support-meetings';
import { ServiceUserPatientProfileSubscriptions } from '../user-patient-profile-subscriptions';
import { ServiceUserPatientProfileSessions } from '../user-patient-profile-session';
import { ServiceUserPatientProfileMedicalInformations } from '../user-patient-profile-medical-informations';
import { ServiceUserPatientProfileUploadedFiles } from '../user-patient-profile-uploaded-files';
import * as dynamic from 'utils/dynamic-helpers';
import { ServiceUserPatientProfileActivities } from '../user-patient-profile-activities';
import { selectAuthUser } from 'store/auth';
import { RootState } from 'store';
import { i18nAppTranslator } from 'modules/i18n';
import { apiTodoTaskCategories, TaskTag } from '../todo-task-categories';
import { makeChangeLog, schemaChangeLogs } from 'modules/change-log';
import { logConfig } from './log';
import { makeFilterPhone } from './helpers';
import { DateValue } from 'utils/dates';
import { calcBmi } from 'utils/app-helpers';
import { ServiceUserPatientProfileSessionItems } from '../user-patient-profile-session-item';
import { SELECT_PATIENT_CARD_INFO } from './queries';
export * from './models';
export * from './queries';

interface SendSmsInput {
  userPatientProfileID: string;
  toPhoneNumber: string;
  message: string;
}

class Service extends DynamicService<UserPatientProfile> {
  async patch(input: Partial<UserPatientProfile>) {
    return super.patch(input);
  }

  getPatientForGeneralForm = async (appIdentityUserID: string) => {
    return this.getDynamic<UserPatientProfileEditGeneralInput>(appIdentityUserID, {
      select: Object.keys(new UserPatientProfileEditGeneralInput()).join(','),
    });
  };
  get = async (id: string) => {
    return this.engine.get<UserPatientProfile>(API_USER_PATIENT_PROFILE.GET(id));
  };
  sendSms = async (input: SendSmsInput) => {
    return this.engine.post(API_USER_PATIENT_PROFILE.SEND_SMS, input);
  };

  createNew = async (input: UserPatientProfileCreateInput) => {
    const { excelFile, ...rest } = input;
    const payload = { ...rest, isActive: false };

    const result = await this.post(payload);

    if (isFileLike(excelFile)) {
      const { appIdentityUserID, idNumber } = result.data;

      const { ext } = getFileInfo(excelFile);

      await ServiceMigrations.migratePatientFromExcel({
        fileExcel: { ...excelFile, name: `${idNumber}.${ext}` },
        userPatientProfileID: String(appIdentityUserID),
      });
    }

    return result;
  };

  getCountNotDoneTasks = async (id: string) => {
    return this.getDynamic<{ count: number }>(id, {
      select: 'toDoTasks.Where(k => k.done != true).Count() as count',
    });
  };

  getSecret = async (appIdentityUserID: string) => {
    return this.engine.get<string>(API_USER_PATIENT_PROFILE.GET_SECRET(appIdentityUserID));
  };

  private checkSecretExpiration = async (
    appIdentityUserID: string,
  ): Promise<CheckSecretExpirationOutput> => {
    const { data: secret } = await this.getSecret(appIdentityUserID);

    if (!secret) {
      return {
        isExpired: true,
      };
    }

    const expired = secret.split('_')[0];

    if (!expired) {
      return {
        isExpired: true,
      };
    }

    const timeStamp = parseInt(expired);

    if (isNaN(timeStamp)) {
      return {
        isExpired: true,
      };
    }

    const expiredAt = new Date(timeStamp);
    const isExpired = isAfter(new Date(), expiredAt);

    if (isExpired) {
      return { isExpired: true };
    }

    return { isExpired: false, secret, expiredAt };
  };

  private makePatientBackDoorNew = async (
    input: MakePatientBackDoorInput,
  ): Promise<{ data: MakePatientBackDoorResult }> => {
    const expired = addMinutes(new Date(), input.minutes);
    const secret = `${expired.getTime()}_${getRandomString(30)}`;

    await this.patch({
      appIdentityUserID: input.userPatientProfileID,
      secret: secret,
    });

    return { data: { userPatientProfileID: input.userPatientProfileID, secret, expired } };
  };

  makePatientBackDoor = async (
    input: MakePatientBackDoorInput,
  ): Promise<{ data: MakePatientBackDoorResult }> => {
    const result = await this.checkSecretExpiration(input.userPatientProfileID);

    if (!result.isExpired) {
      return {
        data: {
          userPatientProfileID: input.userPatientProfileID,
          secret: result.secret,
          expired: result.expiredAt,
        },
      };
    }

    return this.makePatientBackDoorNew(input);
  };

  getByPhone = async (phone: string) => {
    const response = await this.getAllDynamic({
      filter: makeFilterPhone(phone),
      select: 'appIdentityUserID',
    });

    return DynamicService.transformResponseToItemMaybe(response);
  };
  validatePhoneNumber = async (input: PatientVerifyPhoneNumberInput) => {
    const { phone, isNew } = input;

    const {
      data: { count, value },
    } = await this.getAllDynamic({
      filter: makeFilterPhone(phone),
      select: 'appIdentityUserID',
      count: true,
    });

    if (count === 0) {
      return { isValid: true, appIdentityUserID: null };
    }

    return { id: value[0]?.appIdentityUserID, isValid: !isNew ? count === 1 : false };
  };

  getPatientDetailsForDocumentSign = async (userPatientProfileID: string) => {
    return this.getDynamic<PatientDetailsForDocumentSign>(userPatientProfileID, {
      select: [
        'firstName',
        'lastName',
        'fullName',
        'idNumber',
        'dateOfBirth',
        'mobilePhone',
        'email',
        'address',
        'familyMemberName',
        'familyMemberFirstName',
        'familyMemberLastName',
        'familyMemberPhone',
        'familyMemberIDNumber',
        'familyMemberRelationship',
        'userPatientProfileSubscriptions.Where(s => s.isActive).OrderByDescending(s => s.endDate).Select(s => new { s.id, s.startDate, s.endDate }).FirstOrDefault() as subscription',
      ].join(','),
    });
  };
  getPatientDetailsForPdfGimel29 = async (userPatientProfileID: string) => {
    return this.getDynamic<PatientDetailsForPdfGimel29>(userPatientProfileID, {
      select: [
        'fullName',
        'idNumber',
        'dateOfBirth',
        'weight',
        'height',
        'address',
        'mobilePhone',
        'medicalRemarks',
        'new { gender.title} as gender',
      ].join(','),
    });
  };

  getPatientsForSearch = async (input: GetPatientsForSearchInput) => {
    const { take, search, excludeIDs } = input;
    const params = {
      select: [
        'id',
        'appIdentityUserID',
        'rowIndex',
        'fullName',
        'idNumber',
        'mobilePhone',
        'shortRemark',
        'isActive',
        'onHold',
      ].join(','),
      filter: dynamic
        .mergeFilters(
          dynamic.createFilterContains<UserPatientProfile>(['idNumber', 'email'], search),
          dynamic.createFilterPhoneNumber<UserPatientProfile>('mobilePhone', search),
          dynamic.createFilterPhoneNumber<UserPatientProfile>('familyMemberPhone', search),
          dynamic.createFilterSmartSearch<UserPatientProfile>(['firstName', 'lastName'], search),
          dynamic.createFilterContainsPartial<UserPatientProfile>(
            ['firstName', 'lastName'],
            search,
          ),
          !search &&
            dynamic.createFilterNotEqualsSome<UserPatientProfile>('appIdentityUserID', excludeIDs),
        )
        .join('||'),
      take,
      count: true,
    };
    return this.getAllDynamic<ISearchPatient, typeof params>(params);
  };

  private validatePatientsFieldsUnique = async (input: ValidatePatientsFieldsUniqueInput) => {
    const { userPatientProfileID, ...rest } = input;
    const result = await this.getAllDynamic<UserPatientProfile>({
      filter: dynamic
        .mergeFilters(
          `appIdentityUserID!="${userPatientProfileID}"`,
          ...Object.entries(rest).map(([name, value]) => dynamic.createFilterEquals(name, value)),
        )
        .join('&&'),
      select: 'appIdentityUserID',
      take: 1,
    });

    return result.data.value.length === 0;
  };
  validatePatientIdNumber = async (input: ValidatePatientIdNumberInput) => {
    const { userPatientProfileID, idNumber } = input;
    return this.validatePatientsFieldsUnique({ userPatientProfileID, idNumber });
  };
}

export const ServiceUserPatientProfile = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USER_PATIENT_PROFILE.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE.POST,
  patch: API_USER_PATIENT_PROFILE.PATCH,
  delete: API_USER_PATIENT_PROFILE.DELETE,
});

export const getPartPatientSchema = (input: {
  userPatientProfileID: string | null;
  showDietitian: boolean;
}) => {
  const debounceValidateIdNumber = debounceAsync(
    async (input: { userPatientProfileID: string | null; idNumber: string }) => {
      return ServiceUserPatientProfile.validatePatientIdNumber(input);
    },
    1000,
  );

  const { userPatientProfileID, showDietitian } = input;
  return yup.object({
    idNumber: yup
      .string()
      .nullable()
      .concat(ruleIDNumber)
      .test('test-duplicates', 'rule-duplicate', (idNumber: string) => {
        if (!idNumber) return false;

        return debounceValidateIdNumber({ userPatientProfileID, idNumber });
      }),
    userEmployeeProfileDietitianID: showDietitian
      ? yup.string().required('rule-required')
      : yup.string().nullable().notRequired(),
  });
};

export const apiUserPatientProfile = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getPatientForGeneralForm: builder.query<UserPatientProfileEditGeneralInput, string>({
      queryFn: async (userPatientProfileID) => {
        try {
          const result = await ServiceUserPatientProfile.getPatientForGeneralForm(
            userPatientProfileID,
          );

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, err, userPatientProfileID) => [
        { type: RTK_TAGS.PATIENT, id: `form__${userPatientProfileID}` },
      ],
    }),
    getPatientByPhone: builder.query<UserPatientProfile | null, string>({
      queryFn: async (phone) => {
        try {
          const result = await ServiceUserPatientProfile.getByPhone(phone);

          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (res, err, userPatientProfileID) => [
        { type: RTK_TAGS.PATIENT, id: `form__${userPatientProfileID}` },
      ],
    }),
    getPatientDetailsForSupportMeeting: builder.query({
      queryFn: async (appIdentityUserID: string) => {
        const { data } = await ServiceUserPatientProfile.getDynamic<
          Pick<UserPatientProfile, 'appIdentityUserID' | 'firstName' | 'lastName' | 'mobilePhone'> &
            IPatientCardInfo
        >(appIdentityUserID, {
          select: dynamic.select(
            'appIdentityUserID',
            'firstName',
            'lastName',
            'mobilePhone',
            SELECT_PATIENT_CARD_INFO(),
          ),
        });

        return { data };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsForPreviewSurvey: builder.query({
      queryFn: async (appIdentityUserID: string) => {
        const { data } = await ServiceUserPatientProfile.getDynamic<PatientDetailsForPreviewSurvey>(
          appIdentityUserID,
          {
            select: dynamic.select(
              'appIdentityUserID',
              'lastName',
              'firstName',
              'idNumber',
              'dateOfBirth',
              'height',
              'weight',
              'email',
              'mobilePhone',
              'address',
              'occupation',
              'new { city.title } as city',
            ),
          },
        );

        return { data };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsForTask: builder.query({
      queryFn: async (appIdentityUserID: string) => {
        const { data } = await ServiceUserPatientProfile.getDynamic<
          Pick<
            UserPatientProfile,
            | 'appIdentityUserID'
            | 'userEmployeeProfileSupportPrimaryID'
            | 'userEmployeeProfileSupportSecondaryID'
            | 'userEmployeeProfileDietitianID'
          >
        >(appIdentityUserID, {
          select: [
            'appIdentityUserID',
            'userEmployeeProfileSupportPrimaryID',
            'userEmployeeProfileSupportSecondaryID',
            'userEmployeeProfileDietitianID',
          ].join(','),
        });

        return { data };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsForPdfDietitianReport: builder.query({
      queryFn: async (appIdentityUserID: string) => {
        const { data } = await ServiceUserPatientProfile.getDynamic<
          Pick<
            UserPatientProfile,
            | 'firstName'
            | 'lastName'
            | 'mobilePhone'
            | 'dateOfBirth'
            | 'idNumber'
            | 'medicalRemarks'
            | 'hmo'
          >
        >(appIdentityUserID, {
          select: [
            'firstName',
            'lastName',
            'mobilePhone',
            'dateOfBirth',
            'idNumber',
            'medicalRemarks',
            'new { hmo.title } as hmo',
          ].join(','),
        });

        return { data };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsForPdfEmptyLetter: builder.query<PatientDetailsForPdfEmptyLetter, string>({
      queryFn: async (appIdentityUserID) => {
        const { data } =
          await ServiceUserPatientProfile.getDynamic<PatientDetailsForPdfEmptyLetter>(
            appIdentityUserID,
            {
              select: dynamic.select(
                'firstName',
                'lastName',
                'mobilePhone',
                'dateOfBirth',
                'idNumber',
                'new { hmo.title } as hmo',
              ),
            },
          );

        return { data };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsForPdfLetterForMedInsurance: builder.query<
      GetPatientDetailsForPdfLetterForMedInsurance,
      { userPatientProfileID: string; userPatientProfileSubscriptionID: string }
    >({
      queryFn: async (input) => {
        try {
          const { data } =
            await ServiceUserPatientProfile.getDynamic<GetPatientDetailsForPdfLetterForMedInsurance>(
              input.userPatientProfileID,
              {
                select: dynamic.select(
                  'firstName',
                  'lastName',
                  'idNumber',
                  'mobilePhone',
                  'dateOfBirth',
                  'height',
                  'weight',
                  'medicalRemarks',
                  'new { hmo.title } as hmo',
                  'new { diagnosisType.title } as diagnosisType',
                  'new { bmiSummary.title } as bmiSummary',
                  `userPatientProfileSubscriptions.Where(s => ${dynamic.createFilterEquals(
                    's.id',
                    input.userPatientProfileSubscriptionID,
                  )}).Select(s => new  {
                      s.price,
                      s.startDate,
                      s.endDate,
                      s.subscription.listPrice,
                      s.subscription.labelKey,
                      s.subscription.visitDietitian,
                      s.subscription.durationMonths
                    }).FirstOrDefault() as subscription`,
                ),
              },
            );

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg.userPatientProfileID }],
    }),
    getPatientDetailsForPdfEndOfTreatmentDetails: builder.query<
      GetPatientDetailsForPdfEndOfTreatmentDetails,
      {
        userPatientProfileID: string;
        userPatientProfileSubscriptionID: string;
      }
    >({
      queryFn: async (input) => {
        try {
          const { data } =
            await ServiceUserPatientProfile.getDynamic<GetPatientDetailsForPdfEndOfTreatmentDetails>(
              input.userPatientProfileID,
              {
                select: dynamic.select(
                  'firstName',
                  'lastName',
                  'idNumber',
                  'mobilePhone',
                  'dateOfBirth',
                  'height',
                  'weight',
                  'medicalRemarks',
                  'new { hmo.title } as hmo',
                  'new { diagnosisType.title } as diagnosisType',
                  'new { bmiSummary.title } as bmiSummary',
                  `userPatientProfileSubscriptions.Where(s => ${dynamic.createFilterEquals(
                    's.id',
                    input.userPatientProfileSubscriptionID,
                  )}).Select(s => new  {
                      s.price,
                      s.startDate,
                      s.endDate,
                      s.subscription.listPrice,
                      s.subscription.labelKey,
                      s.subscription.visitDietitian,
                      s.subscription.durationMonths
                    }).FirstOrDefault() as subscription`,
                ),
              },
            );

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg.userPatientProfileID }],
    }),
    getPatientDetailsForPdfHmoLetter: builder.query<
      GetPatientDetailsForPdfHmoLetter,
      {
        userPatientProfileID: string;
        userPatientProfileSubscriptionID: string;
      }
    >({
      queryFn: async (input) => {
        try {
          const { data } =
            await ServiceUserPatientProfile.getDynamic<GetPatientDetailsForPdfHmoLetter>(
              input.userPatientProfileID,
              {
                select: dynamic.select(
                  'firstName',
                  'lastName',
                  'idNumber',
                  'mobilePhone',
                  'dateOfBirth',
                  'height',
                  'weight',
                  'medicalRemarks',
                  'new { hmo.title } as hmo',
                  'new { diagnosisType.title } as diagnosisType',
                  'new { bmiSummary.title } as bmiSummary',
                  `userPatientProfileSubscriptions.Where(s => ${dynamic.createFilterEquals(
                    's.id',
                    input.userPatientProfileSubscriptionID,
                  )}).Select(s => new  {
                      s.startDate,
                      s.subscription.visitDietitian,
                      s.subscription.durationMonths
                    }).FirstOrDefault() as subscription`,
                ),
              },
            );

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg.userPatientProfileID }],
    }),
    getPatientDetailsForPdfEndOfTreatment: builder.query<
      GetPatientDetailsForPdfEndOfTreatment,
      {
        userPatientProfileID: string;
        start: DateValue;
        end: DateValue;
      }
    >({
      queryFn: async (input) => {
        try {
          const [
            { data: patient },
            {
              data: { value: weights },
            },
          ] = await Promise.all([
            ServiceUserPatientProfile.getDynamic<GetPatientDetailsForPdfEndOfTreatmentOutput>(
              input.userPatientProfileID,
              {
                select: dynamic.select(
                  'fullName',
                  'idNumber',
                  'height',
                  'weight',
                  'new { gender.rowIndex } as gender',
                ),
              },
            ),
            ServiceUserPatientProfileSessionItems.getAllDynamic({
              select: dynamic.select(
                'id',
                'userPatientProfileSession.entryDate',
                'entryValueNumber',
              ),
              filter: dynamic
                .mergeFilters(
                  dynamic.createFilterEquals(
                    'userPatientProfileSession.userPatientProfileID',
                    input.userPatientProfileID,
                  ),
                  dynamic.createFilterEquals('fieldInput.inputType.isWeight', true),
                  dynamic.createFilterDateISO('userPatientProfileSession.entryDate', [
                    input.start,
                    input.end,
                  ]),
                )
                .join('&&'),
              orderBy: 'userPatientProfileSession.entryDate desc',
              take: 1,
            }),
          ]);

          const lastWeight = weights[0];
          const startingWeight = patient.weight || 0;
          const currentWeight = lastWeight?.entryValueNumber || 0;

          return {
            data: {
              idNumber: patient.idNumber,
              height: patient.height,
              weight: patient.weight,
              fullName: patient.fullName,
              startingWeight,
              currentWeight,
              startingBMI: calcBmi(startingWeight, Number(patient.height || 1)),
              currentBMI: calcBmi(currentWeight, Number(patient.height || 1)),
              gender: patient.gender,
            },
          };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg.userPatientProfileID }],
    }),
    getPatientDetailsForPdfSummaryOfConsultation: builder.query<
      GetPatientDetailsForPdfSummaryOfConsultation,
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const [{ data: patient }] = await Promise.all([
            ServiceUserPatientProfile.getDynamic<GetPatientDetailsForPdfSummaryOfConsultation>(
              userPatientProfileID,
              {
                select: dynamic.select(
                  'firstName',
                  'lastName',
                  'idNumber',
                  'mobilePhone',
                  'dateOfBirth',
                  'height',
                  'weight',
                  'medicalRemarks',
                  'new { hmo.title } as hmo',
                  'new { diagnosisType.title } as diagnosisType',
                  'new { bmiSummary.title } as bmiSummary',
                ),
              },
            ),
          ]);

          return {
            data: patient,
          };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsPrescriptionRules: builder.query({
      queryFn: async (appIdentityUserID: string) => {
        const { data } = await ServiceUserPatientProfile.getDynamic<
          Pick<UserPatientProfile, 'appIdentityUserID' | 'dateOfBirth' | 'weight' | 'height'>
        >(appIdentityUserID, {
          select: ['appIdentityUserID', 'dateOfBirth', 'weight', 'height'].join(','),
        });

        return { data };
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsForDocumentSign: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        try {
          const result = await ServiceUserPatientProfile.getPatientDetailsForDocumentSign(
            userPatientProfileID,
          );
          return { data: result.data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientRequiredDetailsForGmel29: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        try {
          const { data } =
            await ServiceUserPatientProfile.getDynamic<GetPatientRequiredDetailsForGmel29Output>(
              userPatientProfileID,
              {
                select: [
                  'id',
                  'weight',
                  'height',
                  'medicalRemarks',
                  'new { gender.title, gender.isMissing } as gender',
                ].join(','),
              },
            );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientDetailsFormWeights: builder.query({
      queryFn: async (userPatientProfileID: string) => {
        try {
          const { data } = await ServiceUserPatientProfile.getDynamic<
            Pick<UserPatientProfile, 'id' | 'weight'>
          >(userPatientProfileID, {
            select: ['id', 'weight'].join(','),
          });
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    getPatientActivitiesLogYearly: builder.query({
      queryFn: async (input: {
        userPatientProfileID: string;
        fromYear: number;
        toYear: number;
      }) => {
        const { userPatientProfileID, fromYear, toYear } = input;
        const dateRange = [startOfYear(new Date(fromYear, 1)), endOfYear(new Date(toYear, 1))];
        const payload = { userPatientProfileID, dateRange };
        try {
          const [messages, calls, prescriptions, activities] = await Promise.all([
            ServiceUsePatientProfileSmsMessages.getActivities(payload),
            ServiceUserPatientProfileTelecoms.getActivities(payload),
            ServicePatientPrescription.getActivities(payload),
            ServiceUserPatientProfileActivities.getActivities(payload),
          ]);

          const [documents, signedDocuments, tasks, notes] = await Promise.all([
            ServicePatientDocuments.getActivities(payload),
            ServicePatientFormDocuments.getActivities(payload),
            ServiceTodoTasks.getActivities(payload),
            ServiceUserPatientProfileNotes.getActivities(payload),
          ]);

          const [clinicalMeetings, supportMeetings, subscriptions] = await Promise.all([
            ServiceClinicalMeetings.getActivities(payload),
            ServiceSupportMeetings.getActivities(payload),
            ServiceUserPatientProfileSubscriptions.getActivities(payload),
          ]);

          const [sessions, medicalInformation, uploadedFiles] = await Promise.all([
            ServiceUserPatientProfileSessions.getActivities(payload),
            ServiceUserPatientProfileMedicalInformations.getActivities(payload),
            ServiceUserPatientProfileUploadedFiles.getActivities(payload),
          ]);

          return {
            data: {
              messages,
              calls,
              prescriptions,
              documents,
              signedDocuments,
              tasks,
              notes,
              clinicalMeetings,
              supportMeetings,
              subscriptions,
              sessions,
              medicalInformation,
              uploadedFiles,
              activities,
            },
          };
        } catch (e: any) {
          return { error: e };
        }
      },
      keepUnusedDataFor: 600, // 10 minutes,
    }),
    deactivatePatientByRefund: builder.mutation<void, string>({
      queryFn: async (userPatientProfileID) => {
        try {
          await ServiceUserPatientProfile.patch({
            appIdentityUserID: userPatientProfileID,
            isActive: false,
          });
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    deactivatePatient: builder.mutation<void, string>({
      queryFn: async (userPatientProfileID, { dispatch }) => {
        try {
          const reqPatient = dispatch(
            apiUserPatientProfile.endpoints.getPatientForGeneralForm.initiate(userPatientProfileID),
          );
          reqPatient.unsubscribe();

          const patient = await reqPatient.unwrap();

          await dispatch(
            apiUserPatientProfile.endpoints.patchPatientWithLog.initiate({
              initData: patient,
              formData: {
                appIdentityUserID: userPatientProfileID,
                isActive: false,
              },
              remark: i18nAppTranslator.tp('activity-patient-inactive'),
            }),
          ).unwrap();

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),
    activatePatient: builder.mutation<void, string>({
      queryFn: async (userPatientProfileID, { getState, dispatch }) => {
        try {
          const reqPatient = dispatch(
            apiUserPatientProfile.endpoints.getPatientForGeneralForm.initiate(userPatientProfileID),
          );
          reqPatient.unsubscribe();

          const patient = await reqPatient.unwrap();

          await dispatch(
            apiUserPatientProfile.endpoints.patchPatientWithLog.initiate({
              initData: patient,
              formData: {
                appIdentityUserID: userPatientProfileID,
                isActive: true,
              },
              remark: i18nAppTranslator.tp('activity-patient-active'),
            }),
          ).unwrap();

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg }],
    }),

    patchPatient: builder.mutation({
      queryFn: async (input: PatchPartial<UserPatientProfile, 'appIdentityUserID'>) => {
        try {
          await ServiceUserPatientProfile.patch(input);
          return { data: true, error: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      async onQueryStarted({ appIdentityUserID, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiUserPatientProfile.util.updateQueryData(
            'getPatientForGeneralForm',
            appIdentityUserID,
            (draft) => {
              Object.assign(draft, patch);
            },
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },

      invalidatesTags: (_, __, arg) => [{ type: RTK_TAGS.PATIENT, id: arg.appIdentityUserID }],
    }),
    patchPatientWithLog: builder.mutation<
      void,
      {
        initData: PatchPartial<UserPatientProfilePatchInput, 'appIdentityUserID'>;
        formData: PatchPartial<UserPatientProfilePatchInput, 'appIdentityUserID'>;
        remark: Unset;
      }
    >({
      queryFn: async (input, { getState }) => {
        try {
          const currentUser = selectAuthUser(getState() as RootState);

          const userEmployeeProfileID = currentUser?.appUserID;

          if (!userEmployeeProfileID) {
            throw new Error('patchPatientWithLog: userEmployeeProfileID is required');
          }

          const fields = await makeChangeLog(logConfig, {
            initData: input.initData,
            formData: input.formData,
            update: (formData) => ServiceUserPatientProfile.patch(formData),
            getDefinition: (formData, params) =>
              ServiceUserPatientProfile.getDynamic<UserPatientProfile>(formData.appIdentityUserID, {
                select: params.select,
              }).then((r) => r.data),
          });

          const changes = schemaChangeLogs.cast({ fields }, { stripUnknown: true, assert: false });

          ServiceUserPatientProfileActivities.post({
            entryDate: new Date().toISOString(),
            userPatientProfileID: input.formData.appIdentityUserID,
            userEmployeeProfileID,
            changes: JSON.stringify(changes),
            remarks: input.remark ?? undefined,
          });

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      async onQueryStarted(
        { formData: { appIdentityUserID, ...patch } },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          apiUserPatientProfile.util.updateQueryData(
            'getPatientForGeneralForm',
            appIdentityUserID,
            (draft) => {
              Object.assign(draft, patch);
            },
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },

      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.PATIENT, id: arg.formData.appIdentityUserID },
      ],
    }),
    makePatientBackDoor: builder.mutation({
      queryFn: async (input: MakePatientBackDoorInput) => {
        const { data } = await ServiceUserPatientProfile.makePatientBackDoor(input);
        return { data, error: undefined };
      },
    }),
    makePatientOnHold: builder.mutation<{ taskID: string }, MakePatientOnHoldInput>({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const { userPatientProfileID, onHoldEndDate, onHoldReason } = input;

          const currentUser = selectAuthUser(getState() as RootState);

          const userEmployeeProfileID = currentUser?.appUserID;

          if (!userEmployeeProfileID) {
            throw new Error('MakePatientOnHold: userEmployeeProfileID is required');
          }

          const reqPatient = dispatch(
            apiUserPatientProfile.endpoints.getPatientForGeneralForm.initiate(userPatientProfileID),
          );
          reqPatient.unsubscribe();

          const patient = await reqPatient.unwrap();

          await dispatch(
            apiUserPatientProfile.endpoints.patchPatientWithLog.initiate({
              initData: patient,
              formData: {
                appIdentityUserID: userPatientProfileID,
                onHold: true,
                onHoldEndDate,
                onHoldReason,
              },
              remark: i18nAppTranslator.tp('activity-patient-on-hold'),
            }),
          ).unwrap();

          const requestTaskCategory = dispatch(
            apiTodoTaskCategories.endpoints.getTodoTaskCategoryInfoByTag.initiate(
              TaskTag.PatientOnHold,
            ),
          );
          requestTaskCategory.unsubscribe();

          const category = await requestTaskCategory.unwrap();

          const todoTask = await schemaTodoTaskCreate.validate({
            toDoTaskCategoryID: category.id,
            userPatientProfileID,
            sentByUserEmployeeProfileID: userEmployeeProfileID,
            entryDate: onHoldEndDate,
            message: onHoldReason,
            userEmployeeProfileIDs: category.defaultEmployeeProfileIDs,
          });

          const resultTask = await dispatch(
            apiTodoTasks.endpoints.createTodoTaskWithLog.initiate({
              ...todoTask,
            }),
          );

          if (!isMutationFulfilled(resultTask)) {
            throw new Error('Failed to create patient task');
          }

          return { data: { taskID: resultTask.data.id } };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, input) => [
        { type: RTK_TAGS.PATIENT, id: `form__${input.userPatientProfileID}` },
      ],
    }),
    makePatientUnHold: builder.mutation<void, MakePatientUnHoldInput>({
      queryFn: async (input, { dispatch }) => {
        try {
          const { userPatientProfileID } = input;

          const reqPatient = dispatch(
            apiUserPatientProfile.endpoints.getPatientForGeneralForm.initiate(userPatientProfileID),
          );
          reqPatient.unsubscribe();

          const patient = await reqPatient.unwrap();

          await dispatch(
            apiUserPatientProfile.endpoints.patchPatientWithLog.initiate({
              initData: patient,
              formData: {
                appIdentityUserID: userPatientProfileID,
                onHold: false,
              },
              remark: i18nAppTranslator.tp('activity-patient-un-hold'),
            }),
          ).unwrap();

          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (res, err, input) => [
        { type: RTK_TAGS.PATIENT, id: `form__${input.userPatientProfileID}` },
      ],
    }),
  }),
});

export * from './helpers';
