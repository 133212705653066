import React, { forwardRef, memo } from 'react';

import style from './index.module.scss';

import clsx from 'clsx';
import { Chip, ChipProps } from '@material-ui/core';

interface Props extends Omit<ChipProps, 'children' | 'label'> {
  className?: string;
  children: React.ReactNode;
  priority?: 'medium' | 'high' | 'highest' | 'low';
}

const ShortRemarkComponent = forwardRef<HTMLDivElement, Props>(
  ({ className, priority = 'medium', children, ...rest }, ref) => {
    return (
      <Chip
        ref={ref}
        color={'secondary'}
        {...rest}
        size={'small'}
        label={children}
        className={clsx(style.root, className, style[priority])}
      />
    );
  },
);

export const ShortRemark = memo(ShortRemarkComponent) as typeof ShortRemarkComponent;
