import { DynamicService } from 'utils/service';
import { API_NOTEBOOKS, Notebook, NOTEBOOK_INDEXES, NotebookWithPermissions } from './models';
import { PatchPartial } from 'utils/types';
import { ServiceUserEmployeePermissionNotebooks } from 'services/user-employee-profile-permisson-notebooks';
import { apiRtk } from 'utils/rtk-query';
import * as dynamic from 'utils/dynamic-helpers';
import { isNull, omit, omitBy } from 'lodash-es';
import { i18nAppTranslator } from '../../modules/i18n';
import { ServiceNotebookFieldInput } from '../notebook-field-input';

type NotebookFieldInput = Components.Schemas.NotebookFieldInput;

export * from './models';

class Service extends DynamicService<Notebook> {
  postWithPermissions = async (data: Omit<NotebookWithPermissions, 'id'>) => {
    const { userEmployeeProfilePermissionIDs, ...rest } = data;
    const result = await this.post(rest);

    await ServiceUserEmployeePermissionNotebooks.createBulk({
      notebookID: result.data.id,
      userEmployeeProfilePermissionIDs,
    });

    return result;
  };
  patchWithPermissions = async (data: PatchPartial<NotebookWithPermissions, 'id'>) => {
    const { userEmployeeProfilePermissionIDs, ...rest } = data;
    const result = await this.patch(rest);

    if (userEmployeeProfilePermissionIDs) {
      await ServiceUserEmployeePermissionNotebooks.createBulk({
        notebookID: data.id,
        userEmployeeProfilePermissionIDs,
      });
    }

    return result;
  };
  getNotebookAddWeight = async () => {
    const result = await this.getAllDynamic({
      filter: 'isForWeightOnly==true&&isForSurveyWeight==false',
      take: 1,
    });

    const data = result.data.value[0];

    if (!data) {
      throw new Error('error-notebook-is-for-weight-only');
    }

    return { ...result, data };
  };
  private cloneFields = async (notebookID: string, inputs: NotebookFieldInput[]) => {
    return Promise.all(
      inputs.map((notebookFieldInput) => {
        let payload = omit(notebookFieldInput, ['id', 'notebookID', 'rowIndex']);
        payload = omitBy(payload, isNull);

        return ServiceNotebookFieldInput.post({
          ...payload,
          notebookID,
          fieldInputID: payload.fieldInputID ?? undefined,
        });
      }),
    );
  };
  clone = async (id: string) => {
    const [{ data: fullModel }, { data: additional }] = await Promise.all([
      this.getDynamic(id),
      this.getDynamic<{
        userEmployeeProfilePermissionIDs: string[];
        notebookFieldInputs: NotebookFieldInput[];
      }>(id, {
        select: dynamic.select(
          'notebookFieldInputs as notebookFieldInputs',
          'userEmployeeProfilePermissionNotebooks.Select(k => k.userEmployeeProfilePermissionID) as userEmployeeProfilePermissionIDs',
        ),
      }),
    ]);

    let primaryPayload = omit(fullModel, ['id', 'rowIndex']);

    primaryPayload = omitBy(primaryPayload, isNull);
    primaryPayload.labelKey += ` (${i18nAppTranslator.tp('clone')})`;

    const res = await this.postWithPermissions({
      ...primaryPayload,
      userEmployeeProfilePermissionIDs: additional.userEmployeeProfilePermissionIDs,
    });

    await this.cloneFields(res.data.id, additional.notebookFieldInputs);

    return res;
  };
}

export const ServiceNotebooks = new Service({
  mainField: 'id',
  getAll: API_NOTEBOOKS.GET_ALL_DYNAMIC,
  post: API_NOTEBOOKS.POST,
  patch: API_NOTEBOOKS.PATCH,
  delete: API_NOTEBOOKS.DELETE,
});

export const apiNotebooks = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getNotebooksHealthSurvey: builder.query({
      queryFn: async (arg: void) => {
        const {
          data: { value },
        } = await ServiceNotebooks.getAllDynamic<Pick<Notebook, 'id' | 'labelKey'>>({
          filter: dynamic
            .mergeFilters(
              dynamic.createFilterValueArrayEquals('rowIndex', [
                NOTEBOOK_INDEXES.QUESTIONNAIRE_EN,
                NOTEBOOK_INDEXES.QUESTIONNAIRE_HE,
              ]),
              dynamic.createFilterEquals('isActive', true),
            )
            .join('&&'),
          select: ['id', 'labelKey'].join(','),
        });
        return { data: value };
      },
    }),
    getNotebookByID: builder.query<Notebook, string>({
      queryFn: async (input) => {
        try {
          const { data } = await ServiceNotebooks.getDynamic(input);

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    cloneNotebook: builder.mutation<void, string>({
      queryFn: async (id: string) => {
        try {
          await ServiceNotebooks.clone(id);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
