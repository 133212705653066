import {
  API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS,
  iUserPatientProfilePrescriptionDetails,
} from 'services/user-patient-profile-prescription-details';
import React from 'react';
import { MenuGenerateGimel29 } from './menu-generate-gimel29';
import { createFilterEquals } from 'utils/dynamic-helpers';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { ColumnMedicalPrescriptionDetailRule } from './column-rule';

interface Model
  extends Pick<
    Required<iUserPatientProfilePrescriptionDetails>,
    'id' | 'drugID' | 'includeForm29'
  > {
  catalogName: string;
  userPatientProfileID: string;
  userEmployeeProfileID: string;
}

export default (userPatientProfilePrescriptionID: string): any => ({
  id: 'grid_user-patient-profile-prescription-details',
  store: {
    get: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.GET_ALL_DYNAMIC,
      filters: createFilterEquals(
        'userPatientProfilePrescriptionID',
        userPatientProfilePrescriptionID,
      ),
      selected: [
        'drugID',
        'userPatientProfileSessionClinicDrugID',
        'new { drug.ruleForm29AlwaysRequiredForTheFirstTime, drug.ruleForm29RenewAfterNumberOfMonths, drug.ruleForm29NumberOfPrescriptions, drug.ruleForm29PrescriptionDosageFormTotalUnits, drug.ruleForm29AccumulateDosageFormTotalUnits, drug.ruleForm29MinAge, drug.ruleForm29BMILess30 } as drug',
        'drug.drugRegNum as drugRegNum',
        'userPatientProfilePrescription.userPatientProfile.dateOfBirth',
        'userPatientProfilePrescription.userPatientProfileID',
        'userPatientProfilePrescription.userEmployeeProfileID',
        'userPatientProfilePrescription.entryDate',
      ].join(','),
    },
    create: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.POST,
    },
    patch: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.PATCH,
    },
    delete: {
      url: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.DELETE,
      title: (data: Model) => data.catalogName,
    },
  },
  controlsDropDown: [
    {
      type: 'custom',
      template: ({
        data,
        onTask,
        handleClose,
      }: {
        data: Model;
        onTask: (tasks: string[][]) => void;
        handleClose: () => void;
      }) => {
        const onRefresh = () => {
          onTask([['getData']]);
          handleClose();
        };
        if (!data.includeForm29) return null;

        return (
          <MenuGenerateGimel29
            drugID={data.drugID}
            userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
            userPatientProfileID={data.userPatientProfileID}
            userEmployeeProfileID={data.userEmployeeProfileID}
            userPatientProfilePrescriptionDetailID={data.id}
            onRefresh={onRefresh}
          />
        );
      },
    },
    'edit',
    { type: 'delete', title: 'delete', permission: permissionKey('isAllowToDeleteAnything') },
  ],
  toolbar: {
    title: 'drugs',
    controls: false,
    controlsDefault: {
      new: null,
    },
  },
  columns: [
    {
      title: 'drug',
      field: 'drug.catalogName as catalogName',
      sortable: 'drug.catalogName',
    },
    {
      title: 'dosage-form',
      field: 'dosageForm',
      editor: {
        type: 'string',
        required: true,
      },
    },
    {
      title: 'dosage-form-total',
      field: 'dosageFormTotalDesc',
      editor: {
        type: 'string',
        required: true,
      },
    },
    {
      title: 'dosage-form-daily',
      field: 'dosageFormDaily',
      editor: 'string',
    },
    {
      title: 'instructions',
      field: 'instructions',
      editor: 'string',
    },
    {
      title: 'form-29-rules',
      field: 'id',
      template: (data: any) => <ColumnMedicalPrescriptionDetailRule row={data} />,
    },
    {
      title: 'include-form29',
      field: 'includeForm29',
      template: 'boolean',
      editor: 'boolean',
    },
    {
      title: 'is-sample',
      field: 'isSample',
      template: 'boolean',
      editor: 'boolean',
    },
  ],
  pagination: {
    visible: false,
    size: 'all',
  },
});
