import { DynamicService } from 'utils/service';
import {
  API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES,
  IAppUserPatientProfilePrescriptionActivity,
  UserPatientProfilePrescriptionActivityCreateInput,
} from './models';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { selectAuthUser } from 'store/auth';
import { RootState } from 'store';

export * from './models';

class Service extends DynamicService<IAppUserPatientProfilePrescriptionActivity, 'id'> {}

export const UserPatientProfilePrescriptionActivities = new Service({
  getAll: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.POST,
  patch: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.PATCH,
  delete: API_USER_PATIENT_PROFILE_PRESCRIPTION_ACTIVITIES.DELETE,
});

export const apiUserPatientProfilePrescriptionActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getLatestPrescriptionActivities: build.query({
      queryFn: async (userPatientProfilePrescriptionID: string) => {
        try {
          const {
            data: { value },
          } = await UserPatientProfilePrescriptionActivities.getAllDynamic<
            Required<
              Pick<IAppUserPatientProfilePrescriptionActivity, 'id' | 'entryDate' | 'remarks'>
            >
          >({
            filter: `userPatientProfilePrescriptionID=="${userPatientProfilePrescriptionID}"`,
            select: ['id', 'entryDate', 'remarks'].join(','),
            take: 5,
          });
          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, userPatientProfilePrescriptionID) => [
        {
          type: RTK_TAGS.PATIENT_PRESCRIPTION_ACTIVITIES,
          id: `user_patient_profile_prescriptionID__${userPatientProfilePrescriptionID}`,
        },
      ],
    }),
    createUserPatientProfilePrescriptionActivity: build.mutation({
      queryFn: async (input: UserPatientProfilePrescriptionActivityCreateInput, { getState }) => {
        try {
          const user = selectAuthUser(getState() as RootState);

          if (!user) {
            throw new Error('unexpected-behaviour');
          }

          const res = await UserPatientProfilePrescriptionActivities.post({
            ...input,
            userEmployeeProfileID: String(user.appUserID),
            entryDate: new Date().toISOString(),
          });
          return { data: res.data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
