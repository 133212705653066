import { FixServerObject } from 'utils/types';
import { PatientDocumentCreate } from 'services/user-patient-profile-documents';
import { Drug } from '../drug';
import { UserEmployeeProfile } from '../user-employee-profiles';
import * as yup from 'yup';
import { Gimel29Payload } from '../pdf';

const API_ENDPOINT = 'UserPatientProfilePrescriptionDetails';

export const API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<iUserPatientProfilePrescriptionDetails, 'id'>) =>
    `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<iUserPatientProfilePrescriptionDetails, 'id'>) =>
    `${API_ENDPOINT}/Delete/${data.id}`,
};

export interface iUserPatientProfilePrescriptionDetails
  extends FixServerObject<
    Components.Schemas.UserPatientProfilePrescriptionDetail,
    'drugID' | 'id' | 'userPatientProfilePrescriptionID' | 'dosageFormTotal'
  > {}
export interface PrescriptionDetailCreate
  extends Pick<
      Required<iUserPatientProfilePrescriptionDetails>,
      | 'isActive'
      | 'userPatientProfilePrescriptionID'
      | 'drugID'
      | 'dosageForm'
      | 'dosageFormTotal'
      | 'dosageFormDaily'
      | 'dosageFormTotalDesc'
      | 'instructions'
      | 'includeForm29'
    >,
    Pick<iUserPatientProfilePrescriptionDetails, 'isRenewal'> {}
export interface PrescriptionDetailLatest {
  id: string;
  drugID: string;
  catalogName: string;
  prescriptionDate: string;
  dosageForm: string;
  dosageFormTotal: number;
  dosageFormTotalDesc: string;
  dosageFormDaily: string;
  instructions: string;
  includeForm29: boolean;
  isRenewal: boolean;
}

type OtherDetail = Pick<
  iUserPatientProfilePrescriptionDetails,
  'id' | 'drugID' | 'includeForm29' | 'dosageFormTotal' | 'userPatientProfilePrescriptionID'
> &
  Pick<
    FixServerObject<Components.Schemas.UserPatientProfilePrescription, 'entryDate'>,
    'entryDate'
  >;

export interface PrescriptionDetailRulesGimel29Input {
  prescription: Pick<Components.Schemas.UserPatientProfilePrescription, 'entryDate'>;
  patient: Required<
    Pick<Components.Schemas.UserPatientProfile, 'dateOfBirth' | 'weight' | 'height'>
  >;
  detail: Required<
    Pick<
      iUserPatientProfilePrescriptionDetails,
      'drugID' | 'includeForm29' | 'id' | 'dosageFormTotal'
    >
  >;
  drug: Required<
    Pick<
      Components.Schemas.Drug,
      | 'ruleForm29AlwaysRequiredForTheFirstTime'
      | 'ruleForm29RenewAfterNumberOfMonths'
      | 'ruleForm29NumberOfPrescriptions'
      | 'ruleForm29PrescriptionDosageFormTotalUnits'
      | 'ruleForm29AccumulateDosageFormTotalUnits'
      | 'ruleForm29MinAge'
      | 'ruleForm29BMILess30'
    >
  >;
  otherDetails: OtherDetail[];
}
export interface MakeForm29GimelFilePathNameInput {
  userPatientProfilePrescriptionID: string;
  userPatientProfilePrescriptionDetailID: string;
  isCopy: boolean;
}

export interface CreateDocumentGimel29Input
  extends PatientDocumentCreate,
    MakeForm29GimelFilePathNameInput {}

export enum FORM_29_RULES {
  REQUIRED = 'required',
  EXPIRED = 'expired',
  PRESCRIPTION_UNITS = 'prescription-units',
  MIN_AGE = 'min-age',
  NUMBER_OF_PRESCRIPTIONS = 'number-of-prescriptions',
  BMI_LESS_30 = 'bmi-less-30',
}

export type PrescriptionDetailRulesGimel29Result = Array<
  | {
      type: FORM_29_RULES.REQUIRED;
    }
  | {
      type: FORM_29_RULES.EXPIRED;
      payload: string; // last prescription date
    }
  | {
      type: FORM_29_RULES.PRESCRIPTION_UNITS;
      payload: number; // current dosage form total
    }
  | {
      type: FORM_29_RULES.BMI_LESS_30;
      payload: number; // bmi
    }
  | {
      type: FORM_29_RULES.MIN_AGE;
      payload: number; // current user age
    }
  | {
      type: FORM_29_RULES.NUMBER_OF_PRESCRIPTIONS;
      payload: number; // current number of prescriptions
    }
>;
export interface PrescriptionDetailForGimel29
  extends Pick<iUserPatientProfilePrescriptionDetails, 'id' | 'drug'> {
  userPatientProfileID: string;
  userEmployeeProfileID: string;
  drug: Drug;
  doctor: UserEmployeeProfile;
}

export interface UserPatientProfilePrescriptionDetailFormData
  extends Pick<
    iUserPatientProfilePrescriptionDetails,
    | 'id'
    | 'userPatientProfilePrescriptionID'
    | 'drugID'
    | 'dosageForm'
    | 'dosageFormDaily'
    | 'instructions'
    | 'isActive'
    | 'dosageFormTotalDesc'
    | 'dosageFormTotal'
  > {
  drugRegNum: string;
  drugCatalogName: string;
}

export const schemaPatientPrescriptionDetail = yup.object({
  drugID: yup.string().required('rule-required').default(''),
  userPatientProfilePrescriptionID: yup.string().required('rule-required').default(''),
  dosageForm: yup.string().required('rule-required').default(''),
  dosageFormTotalDesc: yup.string().required('rule-required').default(''),
  dosageFormDaily: yup.string().nullable().notRequired().default(''),
  dosageFormTotal: yup
    .number()
    .typeError('rule-number')
    .integer('rule-integer')
    .required('rule-required')
    .default(0),
  instructions: yup.string().default(''),
  isActive: yup.boolean().default(true),
});

export type CreateDocumentGimel29PdfInput = {
  drugNameEng: string;
  htmlTemplate: string;
  htmlPayload: Gimel29Payload;
  userPatientProfilePrescriptionID: string;
  userPatientProfilePrescriptionDetailID: string;
  userEmployeeProfileID: string;
  userPatientProfileID: string;
  documentTypeID: string;
};

export interface PatientPrescriptionDetailsSample
  extends Pick<
    iUserPatientProfilePrescriptionDetails,
    | 'id'
    | 'userPatientProfilePrescriptionID'
    | 'dosageFormTotalDesc'
    | 'dosageFormDaily'
    | 'instructions'
    | 'includeForm29'
  > {
  id: string;
  userPatientProfilePrescriptionID: string;
  date: string;
  catalogName: string;
}
