import React, { memo, useCallback, useMemo, useState } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { iUserPatientProfilePrescriptionDetails } from 'services/user-patient-profile-prescription-details';
import { Editor } from './editor';
import clsx from 'clsx';
import style from './index.module.scss';
import { ControlsSlot } from './controls-slot';
import { IDataGridRowProps } from 'components/data-grid/model';
import { useMedicalPrescriptionDetailsContext } from '../details-context';
import { composeFunctions } from 'utils';
import { useAppDispatch } from '../../../../store';
import { apiRtk, RTK_TAGS } from '../../../../utils/rtk-query';

interface Model extends Pick<iUserPatientProfilePrescriptionDetails, 'id' | 'drug'> {}

interface Props {
  userPatientProfileID: string;
  userPatientProfilePrescriptionID: string;
  onRefreshPrescriptions: () => void;
}

export const GridMedicalPrescriptionsDetails = memo<Props>(
  ({ userPatientProfileID, userPatientProfilePrescriptionID, onRefreshPrescriptions }) => {
    const dispatch = useAppDispatch();

    const { setRows, rowRules } = useMedicalPrescriptionDetailsContext();
    // state
    const [drug, setDrag] = useState<null | Partial<iUserPatientProfilePrescriptionDetails>>(null);
    const formData = useMemo(
      () => ({
        userPatientProfilePrescriptionID,
        ...(drug || {}),
      }),
      [userPatientProfilePrescriptionID, drug],
    );
    // config
    const [gridConfig] = useState<any>(GridConfig(userPatientProfilePrescriptionID));
    const { grid, onGridHandle, editItemID, setEditItemID, onClose, onRefresh } =
      useFormGrid('details');

    // handlers
    const onGridHandleProxy = useCallback(
      (props) => {
        if (props.type === 'deleteDone') {
          onRefreshPrescriptions();
        } else if (props.type === 'getDone') {
          setRows(props.data?.value || []);
        } else if (props.type === 'onSelectDrug') {
          setDrag({
            drugID: props?.value?.id,
            dosageForm: props?.value?.defaultDosageForm || '',
            dosageFormTotal: parseInt(props?.value?.defaultDosageFormTotal || ''),
            dosageFormTotalDesc: props?.value?.defaultDosageFormTotalDesc || '',
            dosageFormDaily: props?.value?.defaultDosageFormDaily || '',
            instructions: props?.value?.defaultInstructions || '',
          });
          setEditItemID(true);
        }

        if (props.type === 'deleteDone' || props.type === 'pathDone') {
          dispatch(
            apiRtk.util.invalidateTags([
              {
                type: RTK_TAGS.PATIENT_PRESCRIPTION_DETAIL,
                id: `userPatientProfileID__${userPatientProfileID}`,
              },
            ]),
          );
        }
        onGridHandle(props);
      },
      [
        onGridHandle,
        setDrag,
        setEditItemID,
        onRefreshPrescriptions,
        setRows,
        userPatientProfileID,
        dispatch,
      ],
    );

    const rowProps = useCallback<IDataGridRowProps<Model>>(
      ({ row }) => {
        const hasRule = rowRules[row.id]?.length > 0;
        return {
          className: clsx(style.row, { [style.rowRequired]: hasRule }),
        };
      },
      [rowRules],
    );

    return (
      <>
        <DataGrid
          ref={grid}
          config={gridConfig}
          onHandle={onGridHandleProxy}
          rowProps={rowProps}
          ScrollProps={{
            mode: 'visible',
          }}
          renderControlsSlot={(props) => (
            <ControlsSlot
              {...props}
              userPatientProfileID={userPatientProfileID}
              userPatientProfilePrescriptionID={userPatientProfilePrescriptionID}
              onRefresh={onRefresh}
            />
          )}
          className={clsx('grid-title-inside', style.drugsGrid)}
        />
        {editItemID && (
          <Editor
            onClose={onClose}
            onRefresh={composeFunctions(onRefresh, onRefreshPrescriptions)}
            itemID={editItemID}
            initData={formData as any}
          />
        )}
      </>
    );
  },
);
