import { IDataGridColumn } from 'components/data-grid/model';
import { ModuleExportColumn } from 'modules/export-excel/models';

const getColumnField = (fieldGridColumn: string): string => {
  const arr = fieldGridColumn.split(' ');
  return arr[arr.length - 1];
};
export const gidColumnsToExcel = (columns: IDataGridColumn[]): ModuleExportColumn[] => {
  return columns
    .filter((column) => column.excelProps?.include === true)
    .map((column) => {
      return {
        title: column.title,
        field: getColumnField(column.field),
        // @ts-ignore
        type: column.type,
        valueTemplate: column.valueTemplate,
      };
    });
};

export const onChangeDebounceHelper = (debounce: any) => (e: any, value: any) => {
  let value_ = '';
  if (
    e.target &&
    e.target.value !== undefined &&
    e.target.value !== null &&
    e.target.type !== 'checkbox'
  ) {
    value_ = e.target.value;
  } else if (value !== undefined && value !== null) {
    value_ = value;
  }
  debounce();
  return value_;
};
export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 255, g: 255, b: 255 };
};

export const hexAToRGBA = (h: string) => {
  let r: any = 0,
    g: any = 0,
    b: any = 0,
    a: any = 1;

  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];
    a = 255;
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
    a = 255;
  } else if (h.length === 5) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];
    a = '0x' + h[4] + h[4];
  } else if (h.length === 9) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
    a = '0x' + h[7] + h[8];
  }
  a = +(a / 255).toFixed(3);

  return [Number(r), Number(g), Number(b), Number(a)];
};

export const rgbaToRgb = (
  r: number,
  g: number,
  b: number,
  a: number,
  r2 = 255,
  g2 = 255,
  b2 = 255,
) => {
  const r3 = Math.round((1 - a) * r2 + a * r);
  const g3 = Math.round((1 - a) * g2 + a * g);
  const b3 = Math.round((1 - a) * b2 + a * b);
  return 'rgb(' + r3 + ',' + g3 + ',' + b3 + ')';
};

export const rgba2hex = (r: number, g: number, b: number, a: number) => {
  const red = r.toString(16).padStart(2, '0');
  const green = g.toString(16).padStart(2, '0');
  const blue = b.toString(16).padStart(2, '0');
  const alpha = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');
  return `#${red}${green}${blue}${alpha}`;
};
