import {
  API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS,
  CreateDocumentGimel29Input,
  CreateDocumentGimel29PdfInput,
  FORM_29_RULES,
  iUserPatientProfilePrescriptionDetails,
  MakeForm29GimelFilePathNameInput,
  PatientPrescriptionDetailsSample,
  PrescriptionDetailCreate,
  PrescriptionDetailForGimel29,
  PrescriptionDetailLatest,
  PrescriptionDetailRulesGimel29Input,
  PrescriptionDetailRulesGimel29Result,
  UserPatientProfilePrescriptionDetailFormData,
} from './models';
import { ServicePatientDocuments } from 'services/user-patient-profile-documents';
import { DynamicService } from 'utils/service';
import { CONTENT_KEYS, ServiceDocumentTemplates } from 'services/document-templates';
import { ServicePdf } from 'services/pdf';
import { getBase64FileSize } from 'utils/file-uploader';
import { addMonths, differenceInYears } from 'date-fns';
import { convertToDate, maxDate } from 'utils/dates';
import * as dynamic from 'utils/dynamic-helpers';
import { DOCUMENT_TYPES_INDEXES, ServiceDocumentTypes } from 'services/document-types';
import { ServiceUserPatientProfile } from '../user-patient-profile';
import { calcBmi } from 'utils/app-helpers';
import { apiRtk, RTK_TAGS } from 'utils/rtk-query';
import { PatchPartial } from 'utils/types';
import { i18nAppTranslator } from 'modules/i18n';

export * from './models';

const RULE_COUNT_OF_MATHS = 4;

interface LatestForPatientOptions {
  isRenewal?: boolean;
}

class Service extends DynamicService<iUserPatientProfilePrescriptionDetails> {
  create = async (data: PrescriptionDetailCreate) => {
    return this.post(data);
  };
  async getLatestForPatient(userPatientProfileID: string, options?: LatestForPatientOptions) {
    const { isRenewal } = options || {};

    const result = await ServicePatientPrescriptionDetails.getAllDynamic<PrescriptionDetailLatest>({
      filter: [
        dynamic.createFilterEquals<iUserPatientProfilePrescriptionDetails>(
          'userPatientProfilePrescription.userPatientProfileID',
          userPatientProfileID,
        ),
        dynamic.createFilterEquals<iUserPatientProfilePrescriptionDetails>(
          'userPatientProfilePrescription.isCompleted',
          true,
        ),
        isRenewal !== undefined ? `isRenewal==${isRenewal}` : null,
      ]
        .filter(Boolean)
        .join('&&'),
      select: [
        'id',
        'drug.id as drugID',
        'drug.catalogName',
        'userPatientProfilePrescription.entryDate as prescriptionDate',
        'dosageForm',
        'dosageFormTotal',
        'dosageFormTotalDesc',
        'dosageFormDaily',
        'instructions',
        'includeForm29',
        'isRenewal',
      ].join(','),
      orderBy: 'userPatientProfilePrescription.entryDate desc',
      take: 10,
    });

    const mapValues = new Map(result.data.value.map((item) => [item.drugID, item]));

    return { ...result, data: { ...result.data, value: Array.from(mapValues.values()) } };
  }
  createDocumentGimel29 = async (documentData: CreateDocumentGimel29Input) => {
    const { userPatientProfilePrescriptionID, userPatientProfilePrescriptionDetailID, ...rest } =
      documentData;
    await ServicePatientDocuments.postWithFiles({
      ...rest,
      documentURL: {
        ...rest.documentURL,
        name: this.makeForm29GimelFilePathName({
          userPatientProfilePrescriptionID,
          userPatientProfilePrescriptionDetailID,
          isCopy: documentData.isCopy,
        }),
      },
    });
  };

  private _createDocumentGimel29PdfWithWatermark = async (input: CreateDocumentGimel29PdfInput) => {
    const {
      drugNameEng,
      htmlTemplate,
      htmlPayload,
      userPatientProfilePrescriptionID,
      userPatientProfilePrescriptionDetailID,
      userPatientProfileID,
      userEmployeeProfileID,
      documentTypeID,
    } = input;
    const fileName = [
      i18nAppTranslator.t('form-29'),
      drugNameEng,
      i18nAppTranslator.t('copy'),
    ].join(' - ');

    const { data: base64 } = await ServicePdf.generateGimel29({
      htmlTemplate,
      payload: htmlPayload,
    });

    return this.createDocumentGimel29({
      userPatientProfilePrescriptionID,
      userPatientProfilePrescriptionDetailID,

      userEmployeeProfileID,
      userPatientProfileID,
      documentTypeID,
      fileName: fileName,
      isForm29: true,
      isCopy: true,
      documentURL: {
        value: base64,
        name: `${fileName}.pdf`,
        size: getBase64FileSize(base64),
        type: 'application/pdf',
      },
      documentForPrintURL: null,
    });
  };
  private _createDocumentGimel29PdfWithoutWatermark = async (
    input: CreateDocumentGimel29PdfInput,
  ) => {
    const {
      drugNameEng,
      htmlTemplate,
      htmlPayload,
      userPatientProfilePrescriptionID,
      userPatientProfilePrescriptionDetailID,
      userPatientProfileID,
      userEmployeeProfileID,
      documentTypeID,
    } = input;
    const fileName = [i18nAppTranslator.t('form-29'), drugNameEng].join(' - ');

    const { data: base64 } = await ServicePdf.generateGimel29WithoutWatermark({
      htmlTemplate,
      payload: htmlPayload,
    });

    return this.createDocumentGimel29({
      userPatientProfilePrescriptionID,
      userPatientProfilePrescriptionDetailID,

      userEmployeeProfileID,
      userPatientProfileID,
      documentTypeID,
      fileName: fileName,
      isForm29: true,
      isCopy: false,
      documentURL: {
        value: base64,
        name: `${fileName}.pdf`,
        size: getBase64FileSize(base64),
        type: 'application/pdf',
      },
      documentForPrintURL: null,
    });
  };
  async createDocumentsGimel29(userPatientProfilePrescriptionID: string) {
    const [
      prescriptionDetailsList,
      {
        data: { html: htmlTemplate },
      },
      { data: documentType },
    ] = await Promise.all([
      ServicePatientPrescriptionDetails.getGimel29Details(userPatientProfilePrescriptionID),
      ServiceDocumentTemplates.getByContentKey(CONTENT_KEYS.GIMEL_29),
      ServiceDocumentTypes.getDynamicByIndex(DOCUMENT_TYPES_INDEXES.GIMEL_29),
    ]);

    await Promise.all(
      prescriptionDetailsList.map(async (option) => {
        const input = {
          userPatientProfilePrescriptionID,
          userPatientProfilePrescriptionDetailID: option.id,
          userEmployeeProfileID: option.userEmployeeProfileID,
          userPatientProfileID: option.userPatientProfileID,
          documentTypeID: documentType.id,
          htmlTemplate,
          htmlPayload: option,
          drugNameEng: option.drug.drugNameEng || '',
        };

        return Promise.all([
          this._createDocumentGimel29PdfWithWatermark(input),
          this._createDocumentGimel29PdfWithoutWatermark(input),
        ]);
      }),
    );
  }

  async getGimel29Details(userPatientProfilePrescriptionID: string) {
    const params = {
      filter: [
        dynamic.createFilterEquals<iUserPatientProfilePrescriptionDetails>(
          'userPatientProfilePrescriptionID',
          userPatientProfilePrescriptionID,
        ),
        'includeForm29==true',
      ]
        .filter(Boolean)
        .join('&&'),
      select: [
        'id',
        'drug',
        'userPatientProfilePrescription.userPatientProfileID as userPatientProfileID',
        'userPatientProfilePrescription.userEmployeeProfileID as userEmployeeProfileID',
        'userPatientProfilePrescription.userEmployeeProfile as doctor',
      ].join(','),
    };
    const result = await ServicePatientPrescriptionDetails.getAllDynamic<
      PrescriptionDetailForGimel29,
      typeof params
    >(params);

    const firstItem = result.data.value[0];

    if (!firstItem) {
      return [];
    }

    const { data: patient } = await ServiceUserPatientProfile.getPatientDetailsForPdfGimel29(
      firstItem.userPatientProfileID,
    );

    return result.data.value.map((item) => {
      return { ...item, patient: patient };
    });
  }
  rulesGimel29(options: PrescriptionDetailRulesGimel29Input): PrescriptionDetailRulesGimel29Result {
    const { detail, drug, patient, prescription, otherDetails } = options;

    const entryDate = convertToDate(prescription.entryDate);

    let rules: PrescriptionDetailRulesGimel29Result = [];

    const otherDetailsRelativeToTargetDrug = otherDetails.filter(
      (otherDetail) => otherDetail.drugID === detail.drugID,
    );

    const mapPrescriptions = new Map<
      string,
      { userPatientProfilePrescriptionID: string; entryDate: string; details: typeof otherDetails }
    >();

    otherDetailsRelativeToTargetDrug.forEach((item) => {
      const key = String(item.userPatientProfilePrescriptionID);
      const value = mapPrescriptions.get(key);

      if (!value) {
        mapPrescriptions.set(key, {
          userPatientProfilePrescriptionID: key,
          entryDate: item.entryDate,
          details: [item],
        });
      } else {
        mapPrescriptions.set(key, {
          ...value,
          details: [...value.details, item],
        });
      }
    });

    const listPrescriptions = Array.from(mapPrescriptions.values()).sort(
      (a, b) => convertToDate(b.entryDate).getTime() - convertToDate(a.entryDate).getTime(),
    );
    const lastPrescriptionIndex = listPrescriptions.findIndex((prescription) =>
      prescription.details.some((otherDetail) => otherDetail.includeForm29),
    );
    const lastPrescription = listPrescriptions[lastPrescriptionIndex];

    // rule required
    if (drug.ruleForm29AlwaysRequiredForTheFirstTime) {
      if (!lastPrescription) {
        rules.push({ type: FORM_29_RULES.REQUIRED });
      }
    }

    // rule bmi
    if (drug.ruleForm29BMILess30) {
      const bmi = calcBmi(patient.weight || 0, patient.height || 0);
      if (!lastPrescription) {
        if (bmi < 30) {
          rules.push({ type: FORM_29_RULES.BMI_LESS_30, payload: bmi });
        }
      }
    }

    if (drug.ruleForm29RenewAfterNumberOfMonths) {
      // rule number of Months
      const expiredDate = addMonths(entryDate, -drug.ruleForm29RenewAfterNumberOfMonths);
      const lastDate = lastPrescription?.entryDate;

      if (lastDate && convertToDate(lastDate).getTime() <= expiredDate.getTime()) {
        rules.push({ type: FORM_29_RULES.EXPIRED, payload: lastDate });
      }
    }
    const isExpired = rules.some((rule) => rule.type === FORM_29_RULES.EXPIRED);

    // rule min patient age
    if (drug.ruleForm29MinAge) {
      const userAge = differenceInYears(new Date(), convertToDate(patient.dateOfBirth));

      if (userAge < drug.ruleForm29MinAge) {
        if (!lastPrescription || isExpired) {
          rules.push({ type: FORM_29_RULES.MIN_AGE, payload: userAge });
        }
      }
    }

    // rule prescription units
    if (drug.ruleForm29PrescriptionDosageFormTotalUnits) {
      const units = detail.dosageFormTotal || 0;

      if (units >= drug.ruleForm29PrescriptionDosageFormTotalUnits) {
        rules.push({ type: FORM_29_RULES.PRESCRIPTION_UNITS, payload: units });
      }
    }

    // rule number of prescriptions
    if (drug.ruleForm29NumberOfPrescriptions) {
      const lastPrescriptionDate = lastPrescription
        ? convertToDate(lastPrescription.entryDate)
        : null;

      const numberOfPrescriptionsDate = addMonths(entryDate, -RULE_COUNT_OF_MATHS);

      const startDate = maxDate(lastPrescriptionDate, numberOfPrescriptionsDate);

      const prescriptions = listPrescriptions.filter(
        ({ entryDate }) => convertToDate(entryDate).getTime() > startDate.getTime(),
      );

      const count = prescriptions.length;

      if (count >= drug.ruleForm29NumberOfPrescriptions) {
        rules.push({ type: FORM_29_RULES.NUMBER_OF_PRESCRIPTIONS, payload: count });
      }
    }

    return rules;
  }
  makeForm29GimelFilePathName = (input: MakeForm29GimelFilePathNameInput) => {
    return [
      `prs__${input.userPatientProfilePrescriptionID}__detail__${input.userPatientProfilePrescriptionDetailID}`,
      input.isCopy ? '' : 'origin',
    ]
      .filter(Boolean)
      .join('_');
  };
}

export const ServicePatientPrescriptionDetails = new Service({
  getAll: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.POST,
  patch: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.PATCH,
  delete: API_USER_PATIENT_PROFILE_PRESCRIPTION_DETAILS.DELETE,
});

export const apiPatientPrescriptionDetails = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getPatientPrescriptionDetailFormData: builder.query<
      UserPatientProfilePrescriptionDetailFormData,
      string
    >({
      queryFn: async (id) => {
        try {
          const { data } =
            await ServicePatientPrescriptionDetails.getDynamic<UserPatientProfilePrescriptionDetailFormData>(
              id,
              {
                select: [
                  'id',
                  'userPatientProfilePrescriptionID',
                  'drugID',
                  'dosageForm',
                  'dosageFormDaily',
                  'instructions',
                  'isActive',
                  'dosageFormTotalDesc',
                  'dosageFormTotal',
                  'drug.drugRegNum as drugRegNum',
                  'drug.catalogName as drugCatalogName',
                ].join(','),
              },
            );

          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getPatientPrescriptionDetailsSamples: builder.query<
      Array<PatientPrescriptionDetailsSample>,
      string
    >({
      queryFn: async (userPatientProfileID) => {
        try {
          const {
            data: { value },
          } = await ServicePatientPrescriptionDetails.getAllDynamic<PatientPrescriptionDetailsSample>(
            {
              select: dynamic.select(
                'id',
                'userPatientProfilePrescriptionID',
                'drug.catalogName',
                'dosageFormTotalDesc',
                'dosageFormDaily',
                'instructions',
                'includeForm29',
                'userPatientProfilePrescription.entryDate as date',
              ),
              filter: dynamic
                .mergeFilters(
                  dynamic.createFilterEquals(
                    'userPatientProfilePrescription.userPatientProfileID',
                    userPatientProfileID,
                  ),
                  'isSample==true',
                )
                .join('&&'),
            },
          );

          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, userPatientProfileID) => [
        {
          type: RTK_TAGS.PATIENT_PRESCRIPTION_DETAIL,
          id: `userPatientProfileID__${userPatientProfileID}`,
        },
      ],
    }),
    postPatientPrescriptionDetail: builder.mutation<
      iUserPatientProfilePrescriptionDetails,
      Omit<iUserPatientProfilePrescriptionDetails, 'id'>
    >({
      queryFn: async (input) => {
        try {
          const { data } = await ServicePatientPrescriptionDetails.post(input);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    patchPatientPrescriptionDetail: builder.mutation<
      iUserPatientProfilePrescriptionDetails,
      PatchPartial<iUserPatientProfilePrescriptionDetails, 'id'>
    >({
      queryFn: async (input) => {
        try {
          await ServicePatientPrescriptionDetails.patch(input);

          return { data: input as iUserPatientProfilePrescriptionDetails };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    deletePatientPrescriptionDetail: builder.mutation<
      iUserPatientProfilePrescriptionDetails,
      PatchPartial<iUserPatientProfilePrescriptionDetails, 'id'>
    >({
      queryFn: async (input) => {
        try {
          const { data } = await ServicePatientPrescriptionDetails.delete(input);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
