import { DynamicService } from 'utils/service';
import { API_NOTEBOOK_FIELD_INPUTS, NotebookFieldInput } from './models';

export * from './models';

class Service extends DynamicService<NotebookFieldInput> {}

export const ServiceNotebookFieldInput = new Service({
  mainField: 'id',
  getAll: API_NOTEBOOK_FIELD_INPUTS.GET_ALL_DYNAMIC,
  post: API_NOTEBOOK_FIELD_INPUTS.POST,
  patch: API_NOTEBOOK_FIELD_INPUTS.PATCH,
  delete: API_NOTEBOOK_FIELD_INPUTS.DELETE,
});
