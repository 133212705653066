import { AxiosError, AxiosRequestConfig } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ArrayType } from './types';
import { api } from './service';

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> => async (config) => {
    try {
      const result = await api({
        method: 'get',
        ...config,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };

export enum RTK_TAGS {
  EMPLOYEE_WORK_LOGS = 'employee-work-logs',
  EMPLOYEE_SCHEDULES = 'employee-schedules',
  EMPLOYEE_ABSENCES = 'employee-absences',
  PATIENT_PRESCRIPTION = 'patient-prescription',
  PATIENT_PRESCRIPTION_ACTIVITIES = 'patient-prescription-activities',
  PATIENT_PRESCRIPTION_DETAIL = 'patient-prescription-details',
  PATIENT = 'patient',
  ICD10S = 'icd10s',
  DRUGS = 'drugs',
  SESSIONS = 'sessions',
  SUPPORT_MEETINGS = 'SUPPORT_MEETINGS',
  CLINICAL_MEETINGS = 'CLINICAL_MEETINGS',
  SUPPORT_MEETING_ACTIVITIES = 'SUPPORT_MEETING_ACTIVITIES',
  DIET_DIARIES = 'DIET_DIARIES',
  TODO_TASK_SUBJECTS = 'TODO_TASK_SUBJECTS',
  TODO_TASKS = 'TODO_TASKS',
  TODO_TASK_ACTIVITIES = 'TODO_TASK_ACTIVITIES',
  USER_PATIENT_PROFILE_ACTIVITIES = 'USER_PATIENT_PROFILE_ACTIVITIES',
  LEADS = 'LEADS',
}

export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(RTK_TAGS),
  endpoints: () => ({}),
});

export const transformResponseDynamic = <T extends { value: any[] }>(data: T) => {
  return data.value as T['value'];
};
export const transformResponseDynamicItem = <T extends { value: any[] }>(data: T) => {
  const item = data.value[0];
  if (!item) {
    throw new Error('record-not-found');
  }
  return item as ArrayType<T['value']>;
};

export const isMutationRejected = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isMutationFulfilled = <T>(mutationResult: any): mutationResult is { data: T } => {
  return !isMutationRejected(mutationResult);
};

export const adapterRtkItemID = (itemID: string | true) => {
  if (itemID === true) {
    return undefined;
  }
  return itemID;
};
