import { DynamicService } from 'utils/service';
import {
  API_CLINICAL_MEETING_ACTIVITIES,
  ClinicalMeetingActivity,
  ClinicalMeetingActivityPostInput,
} from './models';
import { apiRtk } from 'utils/rtk-query';
import { RootState } from '../../store';
import { selectAuthUser } from '../../store/auth';
import { apiRemarkForPatientCallStatuses, REMARK_REASON } from '../remark-for-patient-call-status';

export * from './models';

class Service extends DynamicService<ClinicalMeetingActivity, 'id'> {}

export const ServiceClinicalMeetingActivities = new Service({
  getAll: API_CLINICAL_MEETING_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_CLINICAL_MEETING_ACTIVITIES.POST,
  patch: API_CLINICAL_MEETING_ACTIVITIES.PATCH,
  delete: API_CLINICAL_MEETING_ACTIVITIES.DELETE,
});

export const apiClinicalMeetingActivities = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getLatestClinicalMeetingActivities: build.query({
      queryFn: async (clinicalMeetingID: string) => {
        try {
          const {
            data: { value },
          } = await ServiceClinicalMeetingActivities.getAllDynamic<
            Pick<ClinicalMeetingActivity, 'id' | 'entryDate' | 'remarks'>
          >({
            filter: `clinicalMeetingID=="${clinicalMeetingID}"`,
            select: ['id', 'entryDate', 'remarks'].join(','),
            orderBy: 'entryDate desc',
            take: 5,
          });
          return { data: value };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    postClinicalMeetingActivity: build.mutation<
      ClinicalMeetingActivity,
      ClinicalMeetingActivityPostInput
    >({
      queryFn: async (input, { dispatch, getState }) => {
        const currentUser = selectAuthUser(getState() as RootState);

        if (!currentUser) {
          throw new Error('PostSupportMeetingActivity: unexpected-behaviour');
        }

        let remarkForPatientCallStatusID = input.remarkForPatientCallStatusID;

        try {
          if (!remarkForPatientCallStatusID) {
            const request = dispatch(
              apiRemarkForPatientCallStatuses.endpoints.getRemarkByReason.initiate(
                REMARK_REASON.AUTO,
              ),
            );
            request.unsubscribe();
            const res = await request.unwrap();
            remarkForPatientCallStatusID = String(res.id);
          }

          return ServiceClinicalMeetingActivities.post({
            entryDate: new Date().toISOString(),

            userEmployeeProfileID: String(currentUser.appUserID),
            ...input,
            remarkForPatientCallStatusID: remarkForPatientCallStatusID,
            remarks: input.remarks ?? undefined,
            changes: input.changes ?? undefined,
          });
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
