import { useCallback, useState } from 'react';
import { IDataGridColumn } from 'components/data-grid/model';
import { requestError } from 'AurionCR/components';
import { ModuleExportExcel } from 'modules/export-excel';
import { gidColumnsToExcel } from '../helpers';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/use-translate';
import { api } from 'utils/service';

type Options = {
  url: string;
  params: any;
  columns: IDataGridColumn[];
  title: string;
};
export const useExportToExcel = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslate();

  const dispatch = useDispatch();
  const exportToExcel = useCallback(
    async ({ url, params, columns, title }: Options) => {
      setLoading(true);
      try {
        const { data } = await api.get(url, { params });

        const excelColumns = gidColumnsToExcel(columns);
        const _columns = excelColumns.map((item) => ({ ...item, title: t(item.title) }));
        const { wb_out } = await ModuleExportExcel.export({
          columns: _columns,
          data: data.value,
          settings: { title },
        });
        await ModuleExportExcel.save({ wb_out, name: title });
        dispatch(notifyRequestResult('success'));
      } catch (e: any) {
        debugger;
        dispatch(notifyRequestResult(requestError(e.e.message), 'error'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, t],
  );

  return {
    exportToExcel,
    loading,
  };
};
