import { DynamicService } from 'utils/service';
import {
  API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES,
  CreateUserPatientProfileSubscriptionActivityLogChangeEndDateInput,
  CreateUserPatientProfileSubscriptionActivityLogChangeStartDateInput,
  IAppUserPatientProfileSubscriptionActivity,
  SubscriptionActivityType,
} from './models';
import { apiRtk } from 'utils/rtk-query';
import { dateFormat } from 'utils/dates';
import { APP_FORMAT_DATE } from 'configs/const';
import { i18nAppTranslator } from 'modules/i18n';
import { RootState } from '../../store';
import { selectAuthUser } from '../../store/auth';

export * from './models';

class Service extends DynamicService<IAppUserPatientProfileSubscriptionActivity, 'id'> {}

export const UserPatientProfileSubscriptionActivities = new Service({
  getAll: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.GET_ALL_DYNAMIC,
  post: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.POST,
  patch: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.PATCH,
  delete: API_USER_PATIENT_PROFILE_SUBSCRIPTION_ACTIVITIES.DELETE,
});

export const apiUserPatientProfileSubscriptionActivities = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    createUserPatientProfileSubscriptionActivityLogChangeEndDate: builder.mutation<
      void,
      CreateUserPatientProfileSubscriptionActivityLogChangeEndDateInput
    >({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const currentUser = selectAuthUser(state);
          const userEmployeeProfileID = currentUser?.appUserID;

          if (!userEmployeeProfileID) {
            throw new Error('unexpected-behaviour');
          }

          const entryDate = new Date().toISOString();

          await Promise.all([
            UserPatientProfileSubscriptionActivities.post({
              subscriptionActivityType: SubscriptionActivityType.Duration,
              isManually: false,
              userPatientProfileSubscriptionID: input.userPatientProfileSubscriptionID,
              userEmployeeProfileID,
              remarks: i18nAppTranslator.tp('activity-subscription-change-end-date', {
                endDate: dateFormat(input.endDate, APP_FORMAT_DATE),
              }),
              entryDate,
            }),
            UserPatientProfileSubscriptionActivities.post({
              subscriptionActivityType: SubscriptionActivityType.Duration,
              isManually: true,
              userPatientProfileSubscriptionID: input.userPatientProfileSubscriptionID,
              userEmployeeProfileID,
              remarks: input.remarks,
              entryDate,
            }),
          ]);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    createUserPatientProfileSubscriptionActivityLogChangeStartDate: builder.mutation<
      void,
      CreateUserPatientProfileSubscriptionActivityLogChangeStartDateInput
    >({
      queryFn: async (input, { dispatch, getState }) => {
        try {
          const state = getState() as RootState;
          const currentUser = selectAuthUser(state);
          const userEmployeeProfileID = currentUser?.appUserID;

          if (!userEmployeeProfileID) {
            throw new Error('unexpected-behaviour');
          }

          const entryDate = new Date().toISOString();
          await Promise.all([
            UserPatientProfileSubscriptionActivities.post({
              subscriptionActivityType: SubscriptionActivityType.Duration,
              isManually: false,
              userPatientProfileSubscriptionID: input.userPatientProfileSubscriptionID,
              userEmployeeProfileID,
              remarks: i18nAppTranslator.tp('activity-subscription-change-start-date', {
                startDate: dateFormat(input.startDate, APP_FORMAT_DATE),
              }),
              entryDate,
            }),
          ]);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
  }),
});
