import { getNewKey } from 'AurionCR/components';
import { APP_FORMAT_DATE } from 'configs/const';
import React, { HTMLAttributes, ReactNode } from 'react';
import { permissionKey } from 'services/user-employee-profile-permissions';
import { GridOnTasks } from '../../models/grid';
import { createFilterDateISO } from 'utils/dynamic-helpers';
import { DateValue } from 'utils/dates';

export const DefaultGridButtonExportToExcel = {
  title: 'export-to-excel',
  permission: permissionKey('isAllowToEditPermissions'),
};

/**
 * Filters
 */
export interface IDataGridFilterComponent {
  type: 'component';
  template: React.ComponentType<IDataGridFilterComponentProps> | null;
  value: '';
  valueTemplate?: string;
  placeholder?: string;
  icon?: string;
  class?: string;
  style?: any;
}

export interface IDataGridFilterComponentProps {
  filter: any;
  value: any;
  onTasks: GridOnTasks;
}

export const componentFilter = (): IDataGridFilterComponent => ({
  type: 'component',
  template: null,
  value: '',
  // eslint-disable-next-line
  valueTemplate: '${data.field}.contains("${data.value}")',
  placeholder: '',
  class: '',
  style: { minWidth: '20rem' },
});

export interface IDataGridFilterSwitcher {
  type: 'switcher';
  value?: string;
  valueTemplate?: string;
  label?: string;
  placeholder?: string;
  class?: string;
  style?: any;
}

export const switcherFilter = (): IDataGridFilterSwitcher => ({
  type: 'switcher',
  value: '',
  // eslint-disable-next-line
  valueTemplate: '${data.field} == "${data.value}"',
  placeholder: 'search',
  class: '',
  style: { minWidth: '20rem' },
});

export interface IDataGridFilterString {
  type: 'string';
  value?: string;
  valueTemplate?: string;
  placeholder?: string;
  icon?: string;
  class?: string;
  style?: any;
}

export const stringFilter = (): IDataGridFilterString => ({
  type: 'string',
  value: '',
  // eslint-disable-next-line
  valueTemplate: '${data.field}.contains("${data.value}")',
  placeholder: 'search',
  icon: 'search',
  class: '',
  style: { minWidth: '20rem' },
});

export interface IDataGridFilterSelect {
  type: 'select';
  value?: string | number | any[];
  valueTemplate?: string | number;
  placeholder?: string;
  label?: string;
  clearable: boolean;
  multiple: boolean;
  class?: string;
  style?: any;
  source?:
    | null
    | string
    | any[]
    | {
        url: string;
        filter: string; // es6 template
        // example:
        //    'someFiled == ${data} || someFiled.contains("${data}")
        //    where, ${data} === selectValue
        take: number;
        select?: string;
      };
  option?: {
    label?: string;
    value?: string;
  };
}

export const selectFilter = (): IDataGridFilterSelect => ({
  type: 'select',
  value: '',
  // eslint-disable-next-line
  valueTemplate: '${data.field} == ${isNaN(Number(data.value)) ? `"${data.value}"` : data.value}',
  placeholder: 'select',
  label: '',
  clearable: true,
  multiple: false,
  class: '',
  style: { minWidth: '20rem' },
  source: null,
  option: {
    label: 'title',
    value: 'id',
  },
});

export interface IDataGridFilterDateMultiple {
  type: 'date-multiple';
  value?: any[];
  valueTemplate?:
    | string
    | ((data: { field: string; value: DateValue[] }) => string | null | undefined);
  label?: string;
  placeholder?: string;
  icon?: string;
  class?: string;
  style?: any;
}

export const dateMultipleFilter = (): IDataGridFilterDateMultiple => ({
  type: 'date-multiple',
  value: [],
  valueTemplate: (data) => createFilterDateISO(data.field, data.value),
  label: '',
  placeholder: 'select-data',
  style: { minWidth: '21.5rem' },
});

export interface IDataGridFilterDate {
  type: 'date';
  value?: string | Date;
  format?: string;
  valueFormat?: string;
  valueTemplate?: string;
  label?: string;
  placeholder?: string;
  icon?: string;
  class?: string;
  style?: any;
  pickerOptions?: any;
}

export const dateFilter = (): IDataGridFilterDate => ({
  type: 'date',
  value: '',
  format: APP_FORMAT_DATE,
  valueFormat: 'yyyy,MM,dd',
  // eslint-disable-next-line
  valueTemplate: '${data.field}.Date==DateTime(${data.value})',
  label: '',
  placeholder: 'select-data',
  class: '',
  style: { minWidth: '15rem', maxWidth: '15rem' },
  pickerOptions: {},
});

export interface IDataGridFilter {
  id?: number | string;
  field: string; // any exist or not exist
  options?:
    | null
    | IDataGridFilterSelect
    | IDataGridFilterString
    | IDataGridFilterDateMultiple
    | IDataGridFilterDate
    | IDataGridFilterComponent
    | IDataGridFilterSwitcher;
  permission?: string | null;
  default?: any;
}

export const filterOptions = (type: string | null | undefined) => {
  switch (type) {
    case 'string':
      return stringFilter();
    case 'select':
      return selectFilter();
    case 'date-multiple':
      return dateMultipleFilter();
    case 'date':
      return dateFilter();
    case 'component':
      return componentFilter();
    case 'switcher':
      return switcherFilter();
    default:
      return null;
  }
};
export const filter = (type: string): IDataGridFilter => ({
  field: String(getNewKey()),
  permission: null,
  options: filterOptions(type),
  default: null,
});

/**
 * Toolbar
 */
export interface IDataGridToolbarControlsItem {
  title?: string;
  permission?: string | null;
}

export interface IDataGridToolbarControls {
  refresh?: IDataGridToolbarControlsItem;
  new?: IDataGridToolbarControlsItem;
  resetFilters?: IDataGridToolbarControlsItem;
  export?: IDataGridToolbarControlsItem;
}

export interface IDataGridToolbar {
  visible?: boolean;
  title?: string;
  excelTitle?: string;
  controls?: IDataGridToolbarControls;
  controlsDefault?: IDataGridToolbarControls;
  filters?: null | IDataGridFilter[];
  filtersMap?: null | any; // helper
}

export const toolbar = (): IDataGridToolbar => ({
  visible: true,
  title: '',
  controls: {
    refresh: {
      title: 'refresh',
      permission: null,
    },
    new: {
      title: 'add-new',
      permission: null,
    },
    resetFilters: {
      title: 'reset-filters',
      permission: null,
    },
    export: DefaultGridButtonExportToExcel,
  },
  controlsDefault: {
    new: {
      title: 'add-new',
      permission: null,
    },
    refresh: {
      title: 'refresh',
      permission: null,
    },
    resetFilters: undefined,
    export: undefined,
  },
  filters: null,
  filtersMap: null,
});

/**
 * ManageTemplates
 */
export interface IDataGridColumnTemplateImage {
  type: 'image';
  src: string; // field || es6 template, example: ${data.someValue}, data = rowData
  title: string; // field || es6 template, example: ${data.someValue}, data = rowData
}

export const columnTemplateImage = () => ({
  type: 'image',
  src: '',
  title: '',
});

export interface IDataGridColumnTemplateDate {
  type: 'date';
  format: string;
}

export const columnTemplateDate = () => ({
  type: 'date',
  format: 'DD/MM/YYYY : HH:mm',
});

/**
 * Editor
 */
export interface IDataGridColumnEditorBoolean {
  type: 'boolean';
  field?: null | string; // you can set custom field name to output by path,
  // default column field value.
  // And add to selected filter by query
  permission?: null | string;
}

export const booleanColumnEditor = () => ({
  type: 'boolean',
  field: null,
  permission: null,
});

export interface IDataGridColumnEditorString {
  type: 'string';
  required?: boolean;
  field?: null | string; // you can set custom field name to output by path,
  // default column field value.
  // And add to selected filter by query
  permission?: null | string;
}

export interface IDataGridColumnEditorDate {
  type: 'date';
  required?: boolean;
  field?: null | string; // you can set custom field name to output by path,
  // default column field value.
  // And add to selected filter by query
  permission?: null | string;
  format?: string;
}

export const stringColumnEditor = () => ({
  type: 'string',
  field: null,
  required: true,
  permission: null,
});

export interface IDataGridColumnEditorNumber {
  type: 'number';
  required?: boolean;
  field?: null | string; // you can set custom field name to output by path,
  // default column field value.
  // And add to selected filter by query
  permission?: null | string;
}

export const numberColumnEditor = () => ({
  type: 'number',
  field: null,
  required: true,
  permission: null,
});

export interface IDataGridColumnEditorSelect {
  type: 'select';
  source: null | string | any[];
  required?: boolean; // default true, but if you set false - in select add button "clean"
  class?: string;
  style?: any;
  option?: {
    label?: string;
    value?: string;
  };
  field?: null | string; // you can set custom field name to output by path,
  // default column field value.
  // And add to selected filter by query
  // multiple: boolean
  permission?: null | string;
}
/**
 * Editor
 */
export interface IDataGridColumnEditorCustom {
  type: 'custom';
  field?: null | string; // you can set custom field name to output by path,
  // default column field value.
  // And add to selected filter by query
  permission?: null | string;
  component?: null | ReactNode;
}
export const customColumnEditor = () => ({
  type: 'custom',
  field: null,
  permission: null,
  component: null,
});

export const dateColumnEditor = (): IDataGridColumnEditorDate => ({
  type: 'date',
  field: null,
  permission: null,
  required: false,
  format: APP_FORMAT_DATE,
});

export const selectColumnEditor = () => ({
  type: 'select',
  source: null,
  field: null,
  required: true,
  permission: null,
  option: {
    label: 'title',
    value: 'id',
  },
});

export const getColumnEditor = (name: string) => {
  switch (name) {
    case 'boolean':
      return booleanColumnEditor();
    case 'string':
      return stringColumnEditor();
    case 'number':
      return numberColumnEditor();
    case 'select':
      return selectColumnEditor();
    case 'date':
      return dateColumnEditor();
    case 'custom':
      return customColumnEditor();
    default:
      return null;
  }
};

interface IDataGridExcelProps {
  include: boolean;
}
/**
 * Column
 */
export interface IDataGridColumn {
  title: string;
  field: string; // someFiled (use field by default sortable, editable ...)
  // NOTE:  you can use 'language.title as language'
  //        add this string to query selected and set field 'language' - extract from {... as "fieldName"}
  permission?: string | undefined;
  key?: string;
  sortable?:
    | boolean
    | string
    | {
        // default true or 'someField' or {asc: 'someField' (desc: automatically column.field}
        asc?: string;
        desc?: string;
      };
  template?:
    | string // es6 template, example: ${data.someValue}, data = rowData
    | 'string'
    | 'number'
    | 'email'
    | 'link'
    | 'date'
    | 'boolean'
    | 'image'
    | 'html'
    | Function
    | IDataGridColumnTemplateImage
    | IDataGridColumnTemplateDate;
  editor?:
    | null
    | 'boolean'
    | 'string'
    | 'number'
    | IDataGridColumnEditorString
    | IDataGridColumnEditorBoolean
    | IDataGridColumnEditorNumber
    | IDataGridColumnEditorCustom
    | IDataGridColumnEditorSelect;
  thClasses?: string;
  thStyles?: {};
  tdClasses?: string;
  tdStyle: {};
  width?: string; // NOTE: if table-layout: fixed; - column width == this value else this is min-width;
  cellProps?: IDataGridCellProps;
  excelProps?: IDataGridExcelProps;

  valueTemplate?: any; // using for excel
}

export const column = (): IDataGridColumn => ({
  key: String(getNewKey()),
  title: '',
  field: '',
  permission: '',
  sortable: true,
  template: 'string',
  editor: null,
  thClasses: '',
  thStyles: {},
  tdClasses: '',
  tdStyle: {},
  width: 'auto',
  excelProps: {
    include: true,
  },
});

/**
 * Pagination
 */
export interface IDataGridPagination {
  visible: boolean;
  page: number; // init page
  size: number | 'all'; // init page size
  total: number;
  sizes: {
    visible: true;
    values: {
      label: string;
      value: number | string;
    }[];
  };
}

export const pagination = (): IDataGridPagination => ({
  visible: true,
  page: 1,
  size: process.env.NODE_ENV === 'development' ? 50 : 500,
  total: 0,
  sizes: {
    visible: true,
    values: [
      {
        label: '5',
        value: 5,
      },
      {
        label: '10',
        value: 10,
      },
      {
        label: '15',
        value: 15,
      },
      {
        label: '25',
        value: 25,
      },
      {
        label: '50',
        value: 50,
      },
      {
        label: '100',
        value: 100,
      },
      {
        label: '500',
        value: 500,
      },
    ],
  },
});

/**
 * Store
 */
export interface IDataGridStore {
  get: {
    url: string; // 'Some/GetAllDynamic'
    selected?: string; // 'someField1,someField2' (parser uniq this field)
    filters?: string; // 'someField==value
    parser?: null | any; // function pare data
    expand?: string;
    orderBy?: string;
  };
  create?: {
    url?: string; // 'Some/Create'
  };
  patch?: {
    url?: string; // 'Path/Put/Create'
    method?: 'patch' | 'put'; // request method
  };
  clone?: {
    url?: string; // 'Path/Put/Create'
  };
  delete?: {
    url?: string; // 'Some/Delete'
    title?: string; // someField or es6 "you sure delete ${data.title}?"
    field?: string;
  };
  autoLoad?: boolean;
  sortable?: {
    field?: string;
    order?: string;
  };
  swap?:
    | {
        field?: string;
        title?: string;
      }
    | undefined;
  mainField: string; // field reference link from data - default: id
}

export const store = (): IDataGridStore => ({
  get: {
    url: '',
    selected: '',
    filters: '',
    parser: null,
    expand: '',
  },
  create: {
    url: '',
  },
  patch: {
    url: '',
    method: 'patch',
  },
  clone: {
    url: '',
  },
  delete: {
    url: '',
    title: '',
    field: 'title',
  },
  sortable: {
    field: '',
    order: '',
  },
  swap: undefined,
  autoLoad: true,
  mainField: 'id',
});

/**
 * Controls
 */
export interface IDataGridControl {
  type?: string | 'edit' | 'delete';
  title?: string;
  permission?: null | string;
  disabled?: boolean;
}

export const control = (): IDataGridControl => ({
  type: '',
  title: '',
  permission: null,
  disabled: false,
});

/**
 * Model
 */
export interface IDataGrid {
  store: IDataGridStore;
  id?: string;
  pagination?: IDataGridPagination;
  columns?: IDataGridColumn[];
  toolbar?: IDataGridToolbar;
  controls?: null | string[] | IDataGridControl[];
  controlsDropDown?: null | string[] | IDataGridControl[];
}

export interface IDataGridMadel {
  store: IDataGridStore;
  id: string;
  pagination: IDataGridPagination;
  columns: IDataGridColumn[];
  toolbar: IDataGridToolbar;
  controls: null | string[] | IDataGridControl[];
  controlsDropDown: null | string[] | IDataGridControl[];
}

export const model = (): IDataGridMadel => ({
  id: String(getNewKey()),
  store: store(),
  pagination: pagination(),
  columns: [],
  toolbar: toolbar(),
  controls: null,
  controlsDropDown: null,
});

interface IDataGridRowPropsOption<R = any> {
  row: R;
}
export interface IDataGridRowProps<R = any> {
  (option: IDataGridRowPropsOption<R>): HTMLAttributes<HTMLTableRowElement>;
}
interface IDataGridCellPropsOption<R = any> {
  row: R;
  field: keyof R;
}
export interface IDataGridCellProps<R = any> {
  (option: IDataGridCellPropsOption<R>): HTMLAttributes<HTMLTableCellElement>;
}
